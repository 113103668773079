<template>
  <div>
    <WaBtn />

    <!-- Main Banner -->
    <MainBanner hide-enquiry="true" />
    <!-- end Main Banner -->
    <div class="container py-8 mx-auto text-center">
      <h1 class="pb-6 text-3xl font-bold text-pw-gold md:text-4xl">
        Message Sending Successful!
      </h1>
      <p class="px-4 text-lg md:px-0 opensan400">
        Thank you for your kind interest in Piano Warehouse Sdn Bhd. We will get
        back to you as soon as possible.
      </p>
      <router-link to="/" class="block w-48 mx-auto">
        <p class="block py-2 my-5 text-white rounded-lg bg-pw-gold">
          Back To Home
        </p>
      </router-link>
    </div>
    <!-- copyRight -->
    <Copyright />
  </div>
</template>

<script>
import WaBtn from "@/components/WaBtn.vue";
import MainBanner from "@/components/MainBanner.vue";
import Copyright from "@/components/Copyright.vue"

export default {
  components: {
    WaBtn,
    MainBanner,
    Copyright
  },
};
</script>

<style>
</style>